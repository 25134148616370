@import '../../theming/typography.scss';

p{
  &.h5 {
    @include font_text;
  }
  &.h1 {
    @include font_h1_mobile;
  }
  &.h2{
    @include font_h2;
  }
  &.h3 {
    @include font_h3;
  }
  &.h4 {
    @include font_h4;
  }
  &.h6 {
    @include font_base;
  }
}
