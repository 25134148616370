@import '../../theming/typography.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}


// .h1 {
//   @include font_h1;
// }

h1 {
  @include font_h1_mobile;
  &.no-dnf {
    font-size: $font-size-bigger;
    line-height: 48px;
    letter-spacing: 0.025em;
  }
}

.h1.h1__title {
  @include font_title;
}

h2 {
  @include font_h2;
  &.no-dnf {
    font-size: $font-size-big;
    line-height: 44px;
    letter-spacing: 0.025em;
  }
}

h3 { //only used on ipad layouts
  @include font_h3;
  &.no-dnf {
    font-size: $font-size-medium;
    line-height: 32px;
    letter-spacing: 0.04167em;
  }
}

h4 {
  @include font_h4;
  &.no-dnf {
    font-size: $font-size-small;
    line-height: 20px;
    letter-spacing: 0.0625em;
  }
}

h5 { //never used so far
  @include font_text;
  &.no-dnf {
    font-size: $font-size-smaller;
    line-height: 18px;
    letter-spacing: 0.07143em;
  }
}

// .screen-desktop {
//   .h1.h1__title {
//     @include font_title;
//   }
//   .h1.h1__mobile {
//     @include font_h1;
//   }
// }
