$font-base-size: 12px;
$font-size-smaller: $font-base-size * 1.16666667; // 14px
$font-size-small: $font-base-size * 1.33333334; // 16px
$font-size-medium: $font-base-size * 2; // 24px
$font-size-big: $font-base-size * 2.66666667; // 32px
$font-size-bigger: $font-base-size * 3.33333334; // 40px
$font-size-large: $font-base-size * 4; // 48px
$font-size-larger: $font-base-size * 5.33333334; // 64px

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type-mobile(
  $min-font-size,
  $mid-font-size,
  $max-font-size,
  $min-line-height,
  $mid-line-height,
  $max-line-height,
  $mobile-min-vw: 320px,
  $mobile-max-vw: 400px
) {
  $u0: unit($min-font-size);
  $u1: unit($mid-font-size);
  $u2: unit($max-font-size);
  $u3: unit($min-line-height);
  $u4: unit($mid-line-height);
  $u5: unit($max-line-height);
  $u6: unit($mobile-min-vw);
  $u7: unit($mobile-max-vw);

  @if $u0 ==
    $u1 and
    $u0 ==
    $u2 and
    $u0 ==
    $u3 and
    $u0 ==
    $u4 and
    $u0 ==
    $u5 and
    $u0 ==
    $u6 and
    $u0 ==
    $u7
  {
    .screen-mobile & {
      font-size: $mid-font-size;
      line-height: $mid-line-height;
      @media screen and (max-height: 700px) and (max-width: 400px),
        screen and (max-width: 374px) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($mid-font-size - $min-font-size)} *
            (
              (100vw - #{$mobile-min-vw}) / #{strip-unit(
                  $mobile-max-vw - $mobile-min-vw
                )}
            )
        );
        line-height: calc(
          #{$min-line-height} + #{strip-unit(
              $mid-line-height - $min-line-height
            )} *
            (
              (100vw - #{$mobile-min-vw}) / #{strip-unit(
                  $mobile-max-vw - $mobile-min-vw
                )}
            )
        );
      }
    }
  }
}

@mixin fluid-type-desktop(
  $min-font-size,
  $mid-font-size,
  $max-font-size,
  $min-line-height,
  $mid-line-height,
  $max-line-height,
  $min-vw: 900px,
  $max-vw: 1440px,
  $xl-vw: 1920px,
  $xxl-vw: 3840px
) {
  $u0: unit($min-vw);
  $u1: unit($max-vw);
  $u2: unit($xl-vw);
  $u3: unit($xxl-vw);
  $u4: unit($min-font-size);
  $u5: unit($mid-font-size);
  $u6: unit($max-font-size);
  $u7: unit($min-line-height);
  $u8: unit($mid-line-height);
  $u9: unit($max-line-height);

  @if $u0 ==
    $u1 and
    $u0 ==
    $u2 and
    $u0 ==
    $u3 and
    $u0 ==
    $u4 and
    $u0 ==
    $u5 and
    $u0 ==
    $u6 and
    $u0 ==
    $u7 and
    $u0 ==
    $u8 and
    $u0 ==
    $u9
  {
    & {
      font-size: $min-font-size;
      line-height: $min-line-height;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($mid-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
        line-height: calc(
          #{$min-line-height} + #{strip-unit(
              $mid-line-height - $min-line-height
            )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $mid-font-size;
        line-height: $mid-line-height;
      }
      @media screen and (min-width: $xl-vw) {
        font-size: calc(
          #{$mid-font-size} + #{strip-unit($max-font-size - $mid-font-size)} *
            ((100vw - #{$xl-vw}) / #{strip-unit($xxl-vw - $xl-vw)})
        );
        line-height: calc(
          #{$mid-line-height} + #{strip-unit(
              $max-line-height - $mid-line-height
            )} * ((100vw - #{$xl-vw}) / #{strip-unit($xxl-vw - $xl-vw)})
        );
      }
      @media screen and (min-width: $xxl-vw) {
        font-size: $max-font-size;
        line-height: $max-line-height;
      }
    }
  }
}

@mixin fluid-type(
  $min-font-size,
  $mid-font-size,
  $max-font-size,
  $min-line-height,
  $mid-line-height,
  $max-line-height,
  $min-vw: 900px,
  $max-vw: 1440px,
  $xl-vw: 1920px,
  $xxl-vw: 3840px,
  $mobile-min-vw: 320px,
  $mobile-max-vw: 400px
) {
  $u0: unit($min-vw);
  $u1: unit($max-vw);
  $u2: unit($xl-vw);
  $u3: unit($xxl-vw);
  $u4: unit($min-font-size);
  $u5: unit($mid-font-size);
  $u6: unit($max-font-size);
  $u7: unit($min-line-height);
  $u8: unit($mid-line-height);
  $u9: unit($max-line-height);
  $u10: unit($mobile-min-vw);
  $u11: unit($mobile-max-vw);

  @if $u0 ==
    $u1 and
    $u0 ==
    $u2 and
    $u0 ==
    $u3 and
    $u0 ==
    $u4 and
    $u0 ==
    $u5 and
    $u0 ==
    $u6 and
    $u0 ==
    $u7 and
    $u0 ==
    $u8 and
    $u0 ==
    $u9 and
    $u0 ==
    $u10 and
    $u0 ==
    $u11
  {
    .screen-mobile & {
      font-size: $mid-font-size;
      line-height: $mid-line-height;
      @media screen and (max-height: 700px) and (max-width: 400px),
        screen and (max-width: 374px) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($mid-font-size - $min-font-size)} *
            (
              (100vw - #{$mobile-min-vw}) / #{strip-unit(
                  $mobile-max-vw - $mobile-min-vw
                )}
            )
        );
        line-height: calc(
          #{$min-line-height} + #{strip-unit(
              $mid-line-height - $min-line-height
            )} *
            (
              (100vw - #{$mobile-min-vw}) / #{strip-unit(
                  $mobile-max-vw - $mobile-min-vw
                )}
            )
        );
      }
    }
    .screen-desktop & {
      font-size: $min-font-size;
      line-height: $min-line-height;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($mid-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
        line-height: calc(
          #{$min-line-height} + #{strip-unit(
              $mid-line-height - $min-line-height
            )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $mid-font-size;
        line-height: $mid-line-height;
      }
      @media screen and (min-width: $xl-vw) {
        font-size: calc(
          #{$mid-font-size} + #{strip-unit($max-font-size - $mid-font-size)} *
            ((100vw - #{$xl-vw}) / #{strip-unit($xxl-vw - $xl-vw)})
        );
        line-height: calc(
          #{$mid-line-height} + #{strip-unit(
              $max-line-height - $mid-line-height
            )} * ((100vw - #{$xl-vw}) / #{strip-unit($xxl-vw - $xl-vw)})
        );
      }
      @media screen and (min-width: $xxl-vw) {
        font-size: $max-font-size;
        line-height: $max-line-height;
      }
    }
  }
}

$min_width: 640px;
// $min_width: 64rem;
$max_width: 1080px;
// $max-width: 108rem;
$ratio_big: 2.25;

//Heading and general font-size mixins
@mixin font_title {
  letter-spacing: 0.02143em;
  // line-height: 100px;
  // font-size: 120px;
  @include fluid-type-desktop(
    100px / 3,
    100px,
    100px * $ratio_big,
    100px / 3,
    100px,
    100px * $ratio_big
  );
}
@mixin font_h1 {
  letter-spacing: 0.025em;
  // line-height: 56px;
  // font-size: $font-size-large; // 48px
  @include fluid-type(
    $font-size-large / 1.5,
    $font-size-large,
    $font-size-large * $ratio_big,
    56px / 1.5,
    56px,
    56px * $ratio_big
  );
}

@mixin font_h1_mobile {
  letter-spacing: 0.02143em;
  // line-height: 48px;
  // font-size: $font-size-bigger; //40px
  @include fluid-type(
    $font-size-bigger / 1.2,
    $font-size-bigger,
    $font-size-bigger * $ratio_big,
    48px / 1.2,
    48px,
    48px * $ratio_big
  );
}

@mixin font_h2 {
  letter-spacing: 0.025em;
  // line-height: 44px;
  // font-size: $font-size-big; //32px
  @include fluid-type(
    $font-size-big / 1.2,
    $font-size-big,
    $font-size-big * $ratio_big,
    44px /1.2,
    44px,
    44px * $ratio_big
  );
}

@mixin font_input {
  letter-spacing: 0.03125em;
  // line-height: 36px;
  // font-size: $font-size-big; //32px
  @include fluid-type(
    $font-size-big / 1.2,
    $font-size-big,
    $font-size-big * $ratio_big,
    36px / 1.2,
    36px,
    36px * $ratio_big
  );
}

@mixin font_h3 {
  //only used on ipad layouts
  letter-spacing: 0.04167em;
  // line-height: 32px;
  // font-size: $font-size-medium; //24px
  @include fluid-type(
    $font-size-medium / 1.2,
    $font-size-medium,
    $font-size-medium * $ratio_big,
    32px / 1.2,
    32px,
    32px * $ratio_big
  );
}

@mixin font_h4 {
  letter-spacing: 0.0625em;
  // line-height: 20px;
  // font-size: $font-size-small; //16px
  @include fluid-type(
    $font-size-smaller,
    $font-size-small,
    $font-size-small * $ratio_big,
    20px / 1.2,
    20px,
    20px * $ratio_big
  );
}

// @mixin h5 {
//   font-size: $font-size-smaller;
//   letter-spacing: 0.07143em;
//   line-height: 18px;
// }

@mixin font_text {
  letter-spacing: 0.07143em;
  // line-height: 18px;
  // font-size: $font-size-smaller; //14px
  @include fluid-type(
    $font-base-size,
    $font-size-smaller,
    $font-size-smaller * $ratio_big,
    18px / 1.2,
    18px,
    18px * $ratio_big
  );
}

@mixin font_base {
  letter-spacing: 0.02em; //not same as design but looks more similar
  // line-height: 14px;
  // font-size: $font-base-size; //12px
  @include fluid-type(
    $font-base-size,
    $font-base-size,
    $font-base-size * $ratio_big,
    18px / 1.2,
    18px,
    18px * $ratio_big
  );
}
